<template>
  <v-app>
    <v-main class="background-gradient" style="margin-top: 0">
      <transition name="scale-transition">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>
<script>
import { inject } from '@vue/composition-api';

export default {
  components: {},
  setup() {
    return {};
  },
};
</script>
